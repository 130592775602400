<template>
    <div class="basic_data_edit">
        <div class="data_content scrollbar">
            <!-- 按钮 -->
            <div class="botton_box">
                <span class="menu_title">学术研究</span>
                <div class="button_list">
                    <sn-button
                        :snButton="snButton"
                        :class="{ showButton: !saveButton }"
                        @handleChange="handleChange"
                    ></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <el-form
                ref="formdata"
                :inline="true"
                :model="formdata.dataDic"
                :rules="rules"
                class="demo-form-inline form_row"
                size="small"
                label-width="200px"
            >
                <el-form-item
                    prop="XSYJCGMC"
                    label="学术研究成果名称"
                    class="from-item-block"
                >
                    <el-input
                        v-model="formdata.dataDic.XSYJCGMC"
                        :disabled="!isEdit"
                    ></el-input>
                </el-form-item>
                <!-- <el-form-item prop="CJSJ" label="时间" class="from-item-block">
                    <el-date-picker
                        v-model="formdata.dataDic.CJSJ"
                        type="date"
                        placeholder="选择日期"
                        value-format="yyyy-MM-dd"
                        :disabled="!isEdit"
                    >
                    </el-date-picker>
                </el-form-item> -->
                <el-form-item
                    prop="YJFX"
                    label="研究方向"
                    class="from-item-block"
                >
                    <el-select
                        v-model="YJFXData"
                        multiple
                        :disabled="!isEdit"
                        @change="changeYJFX"
                    >
                        <el-option
                            v-for="(item, index) in YJFXList"
                            :key="`${index}`"
                            :value="item.CODE"
                            :label="item.LABEL"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                    prop="CJRY"
                    label="参加人员"
                    class="from-item-block"
                >
                    <el-input
                        v-model="formdata.dataDic.CJRY"
                        :disabled="!isEdit"
                    >
                    </el-input>
                </el-form-item>
                <div style="border:1px #ccc solid;padding-right:20px">
                    <el-form-item
                        prop="XSYJCGLB"
                        label="学术研究成果类别"
                        class="from-item-block"
                    >
                        <el-select
                            v-model="formdata.dataDic.XSYJCGLB"
                            :disabled="!isEdit"
                            @change="changeXsyjcglb()"
                        >
                            <el-option
                                v-for="(item, index) in XSYJCGLBList"
                                :key="`${index}`"
                                :value="item.CODE"
                                :label="item.LABEL"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                    <!--  科研课题 1 著作 2 学术论文 3 -->
                    <el-form-item
                        prop="ZYYJNR"
                        label="主要研究内容"
                        class="from-item-block"
                        v-if="formdata.dataDic.XSYJCGLB == '1'"
                    >
                        <el-input
                            v-model="formdata.dataDic.ZYYJNR"
                            :disabled="!isEdit"
                        >
                        </el-input>
                    </el-form-item>
                    <el-form-item
                        prop="WTDW"
                        label="委托单位"
                        class="from-item-block"
                        v-if="formdata.dataDic.XSYJCGLB == '1'"
                    >
                        <el-input
                            v-model="formdata.dataDic.WTDW"
                            :disabled="!isEdit"
                        >
                        </el-input>
                    </el-form-item>
                    <el-form-item
                        prop="KTJB"
                        label="课题级别"
                        class="from-item-block"
                        v-if="formdata.dataDic.XSYJCGLB == '1'"
                    >
                        <el-select
                            v-model="formdata.dataDic.KTJB"
                            :disabled="!isEdit"
                        >
                            <el-option
                                v-for="(item, index) in KTJBList"
                                :key="`${index}`"
                                :value="item.CODE"
                                :label="item.LABEL"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item
                        prop="KTJE"
                        label="课题金额【万元】"
                        class="from-item-block"
                        v-if="formdata.dataDic.XSYJCGLB == '1'"
                    >
                        <el-input
                            onkeyup="this.value = this.value.replace(/[^\d.]/g,'');"
                            v-model="formdata.dataDic.KTJE"
                            :disabled="!isEdit"
                            clearable
                        >
                        </el-input>
                    </el-form-item>
                    <el-form-item
                        label="著作方式"
                        class="from-item-block"
                        v-if="formdata.dataDic.XSYJCGLB == '2'"
                    >
                        <el-select
                            v-model="formdata.dataDic.ZZFS"
                            :disabled="!isEdit"
                        >
                            <el-option
                                v-for="(item, index) in ZZFSList"
                                :key="`${index}`"
                                :value="item.CODE"
                                :label="item.LABEL"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item
                        label="主要研究内容"
                        class="from-item-block"
                        v-if="formdata.dataDic.XSYJCGLB == '2'"
                    >
                        <el-input
                            v-model="formdata.dataDic.ZZZYYJNR"
                            :disabled="!isEdit"
                        >
                        </el-input>
                    </el-form-item>
                    <el-form-item
                        label="期刊名称"
                        class="from-item-block"
                        v-if="formdata.dataDic.XSYJCGLB == '3'"
                    >
                        <el-input
                            v-model="formdata.dataDic.QKMC"
                            :disabled="!isEdit"
                        >
                        </el-input>
                    </el-form-item>
                    <el-form-item
                        label="期刊类型"
                        class="from-item-block"
                        v-if="formdata.dataDic.XSYJCGLB == '3'"
                    >
                        <el-select
                            v-model="formdata.dataDic.QKLX"
                            :disabled="!isEdit"
                        >
                            <el-option
                                v-for="(item, index) in QKLXList"
                                :key="`${index}`"
                                :value="item.CODE"
                                :label="item.LABEL"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                </div>

                <el-form-item label="文件" class="from-item-block">
                    <basic-upload
                        ref="uploadFile"
                        :isEdit="isEdit"
                        :itemId="itemId"
                        :metaData.sync="formdata.metaJson"
                        basicType="document"
                        :disabled="!isEdit"
                        :maxSize="500"
                    ></basic-upload>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import infoMixin from "../0_com_js/info_mixin.js";
export default {
    name: "xsyj",
    mixins: [infoMixin],
    data() {
        return {
            isEdit: false,
            saveButton: true,
            formdata: {
                itemCode: "171",
                dataDic: {
                    XSYJCGMC: "", // 学术研究成果名称
                    CJSJ: "", // 时间
                    YJFX: "", // 研究方向
                    XSYJCGLB: "", // 学术研究成果类别
                    ZYYJNR: "", // 主要研究内容
                    WTDW: "", // 委托单位
                    KTJB: "", // 课题级别
                    KTJE: "", // 课题金额
                    ZZFS: "", // 著作方式
                    ZZZYYJNR: "", // 著作主要研究内容
                    QKMC: "", // 期刊名称
                    QKLX: "", // 期刊类别
                    CJRY: "", // 参加人员
                },
            },
            rules: {
                XSYJCGMC: [
                    {
                        required: true,
                        message: "请填写学术研究成果名称",
                        trigger: "blur",
                    },
                ],
                CJSJ: [
                    {
                        required: true,
                        message: "请选择时间",
                        trigger: "change",
                    },
                ],
                YJFX: [
                    {
                        required: true,
                        message: "请选择研究方向",
                        trigger: "blur",
                    },
                ],
                XSYJCGLB: [
                    {
                        required: true,
                        message: "请选择学术研究成果类别",
                        trigger: "change",
                    },
                ],
                // YCSSCDVALUE: [
                //     { required: true, message: '请选择遗产受损程度', trigger: 'blur' }
                // ]
            },
            YCSSCDList: [],
            SJLXList: [],
            showQTSJXLX: false,
            KTJBList: [], // 课题级别 枚举值列表
            QKLXList: [], // 期刊类型 枚举值列表
            XSYJCGLBList: [], // 学术研究成果类别 枚举值列表
            YJFXList: [], // 研究方向 枚举值列表
            ZZFSList: [], // 著作方式 枚举值列表
            YJFXData: "", // 研究方向 多选
        };
    },
    created() {
        this.GetEnum();
    },
    methods: {
        ...mapActions(["getAllEnumInfobytableID"]),
        async GetEnum() {
            let res = await this.getAllEnumInfobytableID({
                bid: "171",
            });
            let enumList = res || [];
            enumList.forEach((v) => {
                if (v.COLUMNID === "KTJB") {
                    this.KTJBList = v.dataItems;
                }
                if (v.COLUMNID === "QKLX") {
                    this.QKLXList = v.dataItems;
                }
                if (v.COLUMNID === "XSYJCGLB") {
                    this.XSYJCGLBList = v.dataItems;
                }
                if (v.COLUMNID === "YJFX") {
                    this.YJFXList = v.dataItems;
                }
                if (v.COLUMNID === "ZZFS") {
                    this.ZZFSList = v.dataItems;
                }
            });
        },
        GetDataNext() {
            if (this.formdata.dataDic && this.formdata.dataDic.KTJB) {
                this.formdata.dataDic.KTJB = this.formdata.dataDic.KTJB + "";
            }
            if (this.formdata.dataDic && this.formdata.dataDic.QKLX) {
                this.formdata.dataDic.QKLX = this.formdata.dataDic.QKLX + "";
            }
            if (this.formdata.dataDic && this.formdata.dataDic.XSYJCGLB) {
                this.formdata.dataDic.XSYJCGLB =
                    this.formdata.dataDic.XSYJCGLB + "";
            }
            if (this.formdata.dataDic && this.formdata.dataDic.YJFX) {
                this.YJFXData = (this.formdata.dataDic.YJFX + "").split(",");
            }
            if (this.formdata.dataDic && this.formdata.dataDic.ZZFS) {
                this.formdata.dataDic.ZZFS = this.formdata.dataDic.ZZFS + "";
            }
        },
        // 改变研究方向时  由数组转变为 表单的值
        changeYJFX() {
            this.formdata.dataDic.YJFX = this.YJFXData.join(",");
            this.$refs.formdata.validateField("YJFX");
        },
        // 学术研究成果类别
        changeXsyjcglb() {
            console.log(
                "%c 🍆 this.formdata.dataDic.XSYJCGLB: ",
                "font-size:20px;background-color: #6EC1C2;color:#fff;",
                this.formdata.dataDic.XSYJCGLB
            );
            switch (this.formdata.dataDic.XSYJCGLB) {
                case "1": //科研课题
                    this.formdata.dataDic.ZZFS = ""; // 著作方式
                    this.formdata.dataDic.ZZZYYJNR = ""; // 著作主要研究内容

                    this.formdata.dataDic.QKMC = ""; // 期刊名称
                    this.formdata.dataDic.QKLX = ""; // 期刊类别
                    break;
                case "2": //著作
                    this.formdata.dataDic.ZYYJNR = ""; // 主要研究内容 学术
                    this.formdata.dataDic.WTDW = ""; // 委托单位
                    this.formdata.dataDic.KTJB = ""; // 课题级别
                    this.formdata.dataDic.KTJE = ""; // 课题金额

                    this.formdata.dataDic.QKMC = ""; // 期刊名称
                    this.formdata.dataDic.QKLX = ""; // 期刊类别
                    break;
                case "3": //学术论文
                    this.formdata.dataDic.ZYYJNR = ""; // 主要研究内容 学术
                    this.formdata.dataDic.WTDW = ""; // 委托单位
                    this.formdata.dataDic.KTJB = ""; // 课题级别
                    this.formdata.dataDic.KTJE = ""; // 课题金额

                    this.formdata.dataDic.ZZFS = ""; // 著作方式
                    this.formdata.dataDic.ZZZYYJNR = ""; // 著作主要研究内容
                    break;
            }
        },
    },
};
</script>

<style></style>
